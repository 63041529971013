import {PushNotifications} from "@capacitor/push-notifications";
import {updateProfile} from "@/services/api";
import store from "@/store";
import router from "@/router";
import {Capacitor} from '@capacitor/core';

const isPushNotificationsAvailable = () => Capacitor.isPluginAvailable('PushNotifications');

export const requestPushNotifications = () => {
  if (!isPushNotificationsAvailable()) return;
  
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      PushNotifications.register().then();
    }
  });
}
export const listenPushNotificationsRegistration = () => {
  if (!isPushNotificationsAvailable()) return;
  
  PushNotifications.addListener('registration',
    (token) => { // Token
      updateProfile({deviceToken: token.value}).then()
    }
  );
  
  PushNotifications.addListener('registrationError',
    () => { // any
      updateProfile({deviceToken: 'not accepted'}).then()
    }
  );
}

export const listenPushNotificationsReceived = () => {
  if (!isPushNotificationsAvailable()) return;
  
  PushNotifications.addListener('pushNotificationActionPerformed',
    (actionPerformed) => { // @TODO integrate with v2
      const data = actionPerformed.notification.data;
      if (data.route) {
        if (data.id) {
          store.dispatch('setCurrentMission', {id: data.id}).then();
        }
        router.push(data['route']).then()
      }
    }
  );
}
